import React, { useEffect, useState } from 'react';

import {
  Container,
  WinWinContainer,
  Table,
  TableHeader,
  HeaderItem,
  TableBody,
  TableRow,
  RowItem,
  RowItemInput,
  SecondBetInfo,
  SecondBetInfoItem
} from './styles';

export const Landing: React.FC = () => {
  const [valueA, setValueA] = useState(1);
  const [oddA, setOddA] = useState(3);
  const [winA, setWinA] = useState(0);
  const [profitA, setProfitA] = useState(0);
  
  const [valueB, setValueB] = useState(0);
  const [oddB, setOddB] = useState(0);
  const [winB, setWinB] = useState(0);
  const [profitB, setProfitB] = useState(0);

  const [coverAOdd, setCoverAOdd] = useState(0);

  const finalABet = profitA - valueB;
  const finalBBet = profitB - valueA;


  function handleChangeValueA(e: any) {
    setValueA(Number(e.target.value));
  }

  function handleChangeOddA (e: any) {
    setOddA(Number(e.target.value))
  }

  useEffect(() => {
    let winATemp = valueA * oddA;
    let profitATemp = winATemp - valueA;
    
    let oddBTemp = Number((winATemp / profitATemp).toFixed(2));
    profitATemp = Number((profitATemp).toFixed(2));

    setWinA(winATemp);
    setProfitA(profitATemp);

    setOddB(oddBTemp);
    setCoverAOdd(oddBTemp);
  }, [valueA, oddA, winA])

  useEffect(() => {
    let winBTemp = Number((valueB * oddB).toFixed(2));
    let profitBTemp = Number((winBTemp - valueB).toFixed(2));       
    
    setWinB(winBTemp);
    setProfitB(profitBTemp);
  }, [valueB, oddB, winA])

  return (
    <Container>
      <WinWinContainer>
        <Table columnsNumber={4}>
          <h2>Time A</h2>

          <TableHeader colorMode='blue' columnsNumber={4}>
            <HeaderItem>Valor</HeaderItem>
            <HeaderItem>Odd</HeaderItem>
            <HeaderItem>Ganho</HeaderItem>
            <HeaderItem>Lucro</HeaderItem>
          </TableHeader>
          <TableBody>
            <TableRow colorMode='blue' columnsNumber={4}>
              <RowItemInput colorMode='blue'>
                <input
                  type='number'
                  defaultValue={valueA}
                  onChange={handleChangeValueA}
                />
              </RowItemInput>
              <RowItemInput colorMode='blue'>
                <input
                  type='number'
                  defaultValue={oddA}
                  onChange={handleChangeOddA}
                />
              </RowItemInput>
              <RowItem>{winA.toFixed(2)}</RowItem>
              <RowItem>{profitA.toFixed(2)}</RowItem>
            </TableRow>
          </TableBody>
        </Table>

        <Table columnsNumber={4}>
          <h2>Time B</h2>

          <TableHeader colorMode='orange' columnsNumber={4}>
            <HeaderItem>Valor</HeaderItem>
            <HeaderItem>Odd</HeaderItem>
            <HeaderItem>Ganho</HeaderItem>
            <HeaderItem>Lucro</HeaderItem>
          </TableHeader>
          <TableBody>
            <TableRow colorMode='orange' columnsNumber={4}>
              <RowItemInput colorMode='orange'>
                <input
                  type='number'
                  defaultValue={valueB}
                  value={valueB}
                  onChange={(e) => setValueB(Number(e.target.value))}
                />
              </RowItemInput>
              <RowItemInput colorMode='orange'>
                <input
                  type='number'
                  defaultValue={oddB}
                  value={oddB}
                  onChange={(e) => setOddB(Number(e.target.value))}
                />
              </RowItemInput>
              <RowItem>{winB}</RowItem>
              <RowItem>{profitB}</RowItem>
            </TableRow>
          </TableBody>
        </Table>

        <SecondBetInfo>
          <SecondBetInfoItem>
            <span>Valor máximo: {profitA}</span>
          </SecondBetInfoItem>
          <SecondBetInfoItem>
            <span>Odd mínima: {coverAOdd}</span>
          </SecondBetInfoItem>
        </SecondBetInfo>

        <Table columnsNumber={2}>
          <h2>Lucro Final</h2>

          <TableHeader colorMode='blue' columnsNumber={2}>
            <HeaderItem>Time A</HeaderItem>
            <HeaderItem color='#f58a3b'>Time B</HeaderItem>
          </TableHeader>
          <TableBody>
            <TableRow colorMode='blue' columnsNumber={2}>              
              <RowItem currentValue={finalABet}>{finalABet.toFixed(2)}</RowItem>
              <RowItem currentValue={finalBBet} color="#ffb77d">{finalBBet.toFixed(2)}</RowItem>
            </TableRow>
          </TableBody>
        </Table>
      </WinWinContainer>
    </Container>
  );
}