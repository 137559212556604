import React from 'react';
import { Landing } from './pages/Landing';

import GlobalStyle from './styles/global';

function App() {
  return (
    <>
      <Landing/>
      <GlobalStyle />
    </>
  );
}

export default App;