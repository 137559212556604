import styled from 'styled-components';

const colorsStyle = {
  blue: {
    header: '#0a9df2',
    lightRow: '#a7d7f2',
    darkRow: '#78c4f0',
    editable: '#A1C0CE'
  },
  orange: {
    header: '#f58a3b',
    lightRow: '#f2c7a7',
    darkRow: '#ffb77d',
    editable: '#D7BBA0'
  }
}

type colors = 'orange' | 'blue';

interface TableColorsProps {
  colorMode: colors
}

interface TableProps {
  columnsNumber?: number;
  colorMode?: colors;
}

interface TableItemProps {
  currentValue?: number
}

export const Container = styled.div`
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WinWinContainer= styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Table = styled.div<TableProps>`
  display: flex;
  flex-direction: column;
  max-width: ${({ columnsNumber }) => columnsNumber && columnsNumber * 125}px;

  h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 4px;
    margin-left: 18px;
  }

  & + & {
    margin-top: 24px;
  }  
`;

export const TableHeader = styled.div<TableProps>`
  background: ${({ colorMode }) => colorMode === 'blue' 
    ? colorsStyle.blue.header
    : colorsStyle.orange.header
  };
  display: grid;
  grid-template-columns: repeat(${({ columnsNumber }) => columnsNumber}, 1fr);
  border: 2px solid #fff;
`;

export const HeaderItem = styled.div<TableProps>`
  font-size: 24px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 4px;
  background: ${({ color }) => color && color};

  &:not(:first-child){
    border-left: 2px solid #fff;
  }
`;

export const TableBody = styled.div`
`;

export const TableRow = styled.div<TableProps>`
  display: grid;
  grid-template-columns: repeat(${({ columnsNumber }) => columnsNumber}, 1fr);
  border: 2px solid #fff;
  border-top: 0;
  background: ${({ colorMode }) => colorMode === 'blue' 
    ? colorsStyle.blue.lightRow
    : colorsStyle.orange.lightRow
  };

  &:nth-child(odd) {
    background: ${({ colorMode }) => colorMode === 'blue' 
      ? colorsStyle.blue.darkRow
      : colorsStyle.orange.darkRow
    };
  }
`;

export const RowItem = styled.div<TableItemProps>`
  font-size: 20px;
  text-align: center;
  color: #fff;
  padding: 4px;

  background-color: ${({color}) => color && color};

  background-color: ${({currentValue}) => 
    currentValue && currentValue > 0 ? 'lightgreen' : 
    currentValue && currentValue < 0 && 'tomato' 
  };

  &:not(:first-child){
    border-left: 2px solid #fff;
  }
`;

export const RowItemInput = styled.div<TableColorsProps>`
  padding: 0 4px;
  background: ${({ colorMode }) => colorMode === 'blue' 
    ? colorsStyle.blue.editable
    : colorsStyle.orange.editable
  };

  &:not(:first-child){
    border-left: 2px solid #fff;
  }

  input {
    height: 100%;
    max-width: 114px;
    border: 0;
    background-color: transparent;
    font-size: 20px;
    text-align: center;
    color: #fff;
  }
`;

export const SecondBetInfo = styled.div`
  display: flex;
  margin: 4px 0 24px;
`;

export const SecondBetInfoItem = styled.div`
  & + div {
    margin-left: 12px;
  }

  span {
    color: #fff;
    font-size: 18px;
  }
`;
